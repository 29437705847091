import { DatenMitglied } from './../mitglied/mitglied.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Kategorie, Mitglied} from '../mitglied/mitglied.model';
import { GlobaleDatenService } from './globaledaten.serice';
import { AuthService } from './auth.service';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class BeitraegeService {
  readonly volljaehrig = 18; // volljäehrig
  readonly befreitab = 2; // ab 2 Minderjährige kein Beitrag
  readonly befreitabTxt = 'Wenn zwei Kinder in der Familie den Inlandsbeitrag zahlen, egal ob die Eltern AVS-Mitglied sind, ist die AVS-Mitgliedschaft für das 3. und jedes weitere Kind beitragsfrei.<br>Der Versicherungsschutz gilt trotzdem!<br>Für Kinder/Jugendliche mit Inlandsanschrift';
  readonly stichtag: Date = new Date(new Date().getFullYear(), 8, 30); // Wenn vor 30.9, kostenlos bis 31.12

  i = 0;
  mitglied_fam_famreihenfolge = 0;
  constructor(
    public gdService: GlobaleDatenService,
    public authservice: AuthService
  ) {
  }

  async getTarifKategorie(familie: DatenMitglied, mitglied, neu: boolean): Promise<any> {
    const send = new Array();
    this.i = 0;
    if (mitglied != null && mitglied.familie != null) {
      for (const f of mitglied.familie) {
        if (!mitglied.mitglied && mitglied.id === f.id) {
          this.mitglied_fam_famreihenfolge = f.famreihenfolge;
        }
        send.push({reihenfolge_in_familie: f.famreihenfolge, gebdatum: moment(f.gebdatum).format('YYYY-MM-DD'), nation: f.nation});
        this.i++;
      }
    }
    if (mitglied.id != null && mitglied.familie == null && mitglied.mitglied) {
      if (familie['erz1_mgd']) {
        send.push({reihenfolge_in_familie: familie['erz1_mgd'][0].reihenfolge,
                    gebdatum: moment(familie['erz1_mgd'][0].gebdatum).format('YYYY-MM-DD'), nation: familie.mitglied.nation});
        this.mitglied_fam_famreihenfolge = this.i + 1;
        this.i++;
      }
    }
    if (mitglied.id == null || mitglied.fam_id === null || mitglied.mitglied || neu) {
      send.push({reihenfolge_in_familie: familie.mitglied.reihenfolge ? familie.mitglied.reihenfolge : this.i + 1,
        gebdatum: moment(familie.mitglied.gebdatum).format('YYYY-MM-DD'),
        nation: familie.mitglied.nation});
      this.mitglied_fam_famreihenfolge = this.i + 1;
    }
    for (const f of familie.mitglieder) {
      send.push({reihenfolge_in_familie: f.reihenfolge, gebdatum: moment(f.gebdatum).format('YYYY-MM-DD'), nation: familie.mitglied.nation});
    }
    const cat = await this.authservice.putUrl('/portal/mitgliederkategorieoa', send).then((data) => {
      return data;
    }).catch((error) => {
      console.log(error);
    });

    return cat;
  }

  async getTarife(familie: DatenMitglied, kategorien: any[], mitglied, neu: boolean = false): Promise<any> {
    familie.gesamtbeitrag = 0;
    const kat_array = await this.getTarifKategorie(familie, mitglied, neu);
    if (this.mitglied_fam_famreihenfolge === 0) { this.mitglied_fam_famreihenfolge++; }
    let kat = kategorien.find(el => el.id === kat_array[this.mitglied_fam_famreihenfolge - 1].kat_id);
    // familie.mitglied.beitragskategorie = kat;
    familie.mitglied.beitragskategorie = new Kategorie();
    familie.mitglied.beitragskategorie.beitrag = kat.beitrag;
    familie.mitglied.beitragskategorie.id = kat.id;
    familie.mitglied.beitragskategorie.kuerzel = kat.kuerzel;
    familie.mitglied.beitragskategorie.name = kat.name;
    familie.mitglied.beitrag = kat.beitrag;
    familie.gesamtbeitrag += familie.mitglied.beitrag;
    this.i++;
    if (familie.mitglieder && familie.mitglieder.length > 0) {
      let c2 = 0;
      if (!this.istVolljaehrig(familie.mitglied.gebdatum)) { c2++; }
      for (const mitglied of familie.mitglieder) {
        kat =  kategorien.find(el => el.id === kat_array[this.i].kat_id);
        // mitglied.beitragskategorie = kat;
        mitglied.beitragskategorie = new Kategorie();
        mitglied.beitragskategorie.beitrag = kat.beitrag;
        mitglied.beitragskategorie.id = kat.id;
        mitglied.beitragskategorie.kuerzel = kat.kuerzel;
        mitglied.beitragskategorie.name = kat.name;
        mitglied.beitrag = kat.beitrag;
        familie.gesamtbeitrag += mitglied.beitrag;
        this.i++;
      }
    }
    return familie;
  }


  istVolljaehrig(geburt: Date): boolean {
    let maxDate = new Date();
    maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() - this.volljaehrig));
    if (geburt > maxDate) {
      return false;
    }
    return true;
  }

  istInland(land: string): boolean {
    if (land === this.gdService.it_id || land === this.gdService.it_id ){
      return true;
    }
    return false;
  }

}
